import { Routes, Route } from "react-router-dom"
import TopNavBar from "./component/topNavbar/TopNavBar";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import General from "./pages/general/General";
import Team from "./pages/team/Team";
import AboutUs from "./pages/aboutus/AboutUs";
import Activities from "./pages/activities/Activities";
import Deatails from "./pages/details/Deatails";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<TopNavBar />}>
          <Route index element={<Home />} />
          <Route path="/:pictureId" element={<Deatails />} />
          <Route path="contact" element={<Contact />} />
          <Route path="general" element={<General />}>
            <Route index element={<AboutUs />} />
            <Route path="team" element={<Team />} />
            <Route path="activities" element={<Activities />} />
            <Route path="activities/:pictureId" element={<Deatails />}/>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
