import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const EventItem = ({ events }) => {
    const{ picture, caption, description } = events
  return (
    <div className="eventItem">
    <img src={`/images/${picture}`} alt="" />
    <div className="activitiesText">
        <h4>{caption}</h4>
        <p>{description?.slice(0,50)}..</p>
        <div className="activitiesButton">
            <Link to={`${picture}`}>Read More <AiOutlineArrowRight className='arrowRight' /></Link>
        </div>
    </div>
</div>
   
  )
}

export default EventItem