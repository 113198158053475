import React from 'react'
import "./TextArea.scss"

const TextArea = ({ name, change, blur, label }) => {
  return (
    <div className='textAreaContainer'>
        <label>{label}*</label>
        <textarea name={name} onChange={change} onBlur={blur} cols="30" rows="10"></textarea>
    </div>
  )
}

export default TextArea