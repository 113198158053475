import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Footer from '../footer/Footer'
import { AiOutlineHome } from "react-icons/ai"
import { BiSolidContact } from "react-icons/bi"
import { BsBookmarkPlusFill } from "react-icons/bs"
import { SlCallOut } from "react-icons/sl"
// import { MdDarkMode, MdLightMode } from "react-icons/md"
import "./TopNavBar.scss"

const TopNavBar = () => {
  return (
    <>
     <div className='topNavBar'>
        <div className="logoBox">
            <Link to="/"><img src={`/logo/image002.png`} alt="" /></Link>
        </div>
       <ul className="navItems">
        <li className='listItem'>
           <Link to="/"><AiOutlineHome className='icons' /><span>Home</span></Link>
        </li>
        <li className='listItem'>
           <Link to="/general"><BsBookmarkPlusFill className='icons' /><span>Who Are We</span></Link>
        </li>
        <li className='listItem'>
           <Link to="/contact"><BiSolidContact className='icons' /><span>Contact</span></Link>
        </li>
        <li className='listItem'>
           <Link><SlCallOut className='icons' /><span>Live phone call +23276650092</span></Link>
        </li>
       </ul>
       {/* <div className="rightBox">
       <div className="mode">
        <Link><MdLightMode className='icons' /></Link>
       </div>
       <div className="languageBox">
       </div>
       </div> */}
    </div>
    <Outlet />
    <Footer />
    </>
  )
}

export default TopNavBar