import React from 'react'
import { Link } from "react-router-dom"
import "./Sidebar.scss"

const Sidebar = () => {
  return (
    <div className='sidebarContainer'>
        <ul className='sidebarMenu'>
            <li><Link to="/general">About Us</Link ></li>
            <li><Link to="/general/activities">Events/Activities</Link></li>
        </ul>
    </div>
  )
}

export default Sidebar