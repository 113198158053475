import React from 'react'
import "./AreaOperation.scss"
import Regions from '../regions/Regions'

const AreaOperation = () => {
  return (
    <div className='operation'>
      <div className="areaTitle">
        <h2>Area of operation</h2>
      </div>
      <div className="areaText">
        <p>Christian Community Development Programme (CCDP)<br />
          Operate mainly in North-West, North, South and Eastern Provience:</p>
       <Regions />
      </div>
    </div>
  )
}

export default AreaOperation