import React from 'react'
import "./Banner.scss"

const Banner = () => {
  return (
    <div className='banner'>
        <div className="titleBox">
            <h1>Christian Community<br /> Development Programme</h1>
            <h5>Sierra Leone</h5>
        </div>
    </div>
  )
}

export default Banner