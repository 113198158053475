import React from 'react'
import "./Input.scss"

export const Input = ({ type, change, blur, placeholder, name, label }) => {
  return (
    <div className='inputContainer'>
        <label>{label}*</label>
        <input type={type} name={name} placeholder={placeholder} onChange={change} onBlur={blur} />
    </div>
  )
}
