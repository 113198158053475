import React, { useRef } from 'react'
import "./Contact.scss"
import { Input } from '../../component/Input/Input'
import TextArea from '../../component/textarea/TextArea'
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            await emailjs
                .sendForm('service_2pr20gf', 'template_zv606on', form.current, {
                    publicKey: 'S0JX0SZSwSwoI_vhu',
                })
            alert("Email delivery successfully")
            e?.target?.reset()
        } catch (error) {
            alert("Sending email fail: ", error.text)
        }
    };
    return (
        <div className='contactContainer'>
            <h4>Contact Us</h4>
            <form ref={form} onSubmit={sendEmail}>
                <input type='text' name='to_name' value="Mr. Daniel Lakoh" style={{ display: "none" }} />
                <div className="formInput">
                    <Input type="text" name="reply_to" placeholder="Your Email" label="Email" />
                </div>
                <div className="formInput">
                    <Input type="text" name="from_name" placeholder="Your Full name" label="Fullname" />
                </div>
                <div className="formInput">
                    <TextArea name="message" label="Message" />
                </div>
                <button type="submit">Send Message</button>
            </form>
        </div>
    )
}

export default Contact