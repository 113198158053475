import React from 'react'
import "./Home.scss"
import Banner from '../../component/banner/Banner'
import AreaOperation from '../../component/operation/AreaOperation'
import LatestEvent from '../../component/events/LatestEvent'


const Home = () => {
  return (
    <div className='home'>
        <div className="bannerContainer">
        <Banner />
        </div>
        <div className="operationContainer">
        <AreaOperation />
        </div>
        <div className="eventLatest">
          <LatestEvent />
        </div>
    </div>
  )
}

export default Home