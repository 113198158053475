import React from 'react'
import "./LatestEvent.scss"
import { activitiesData } from '../../data/data'
import EventItem from '../eventItem/EventItem'



const LatestEvent = () => {
    return (
        <div className='latestEvent'>
            <div className="eventHeader">
                <h2>Eevents</h2>
            </div>
            <div className="eventContainer">
                {
                    activitiesData.slice(0,6).map((events, index) => (
                      <EventItem key={index} events={events} />
                    ))
                }

            </div>
        </div>
    )
}

export default LatestEvent