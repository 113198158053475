import React from 'react'
import "./Footer.scss"
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const copayRightYear = new Date().getFullYear()
  const navigate = useNavigate()
  return (
    <div className='footer'>
      <div className="whatwedo">
        <h6>What we do</h6>
        <ul>
          <li>Loan to women</li>
          <li>Agriculture</li>
          <li>Vocational Training</li>
          <li>School materials to children</li>
          <li>School feeding</li>
        </ul>
      </div>
      <div className="whoweare">
        <h6>Who we are</h6>
        <ul>
          <li onClick={() => navigate('/general')}>About us</li>
          <li onClick={() => navigate('/')}>Latest activities</li>
          <li onClick={() => navigate('/general/activities')}>Activities</li>
        </ul>
      </div>
      <div className="copayRight">
        <h6>
          Copy Right &copy; {copayRightYear} CCDP
        </h6>
        <p>You are allowed to use this wibsite, <br /> but you sre not allowed to use any content of this <br /> wibsite for personal or commercial use.</p>
      </div>
      <div className="areaWork">
        <h6>Operational area</h6>
        <ul>
          <li>North-West Region</li>
          <li>Nothern Region</li>
          <li>Southern Region</li>
          <li>Eastern Region</li>
        </ul>
      </div>
    </div>
  )
}

export default Footer