import React from 'react'
import Sidebar from '../../component/sidebar/Sidebar'
import "./General.scss"
import { Outlet } from 'react-router-dom'

const General = () => {
  return (
    <div className='general'>
        <Sidebar />
        <div className="mainArea">
           <Outlet />
        </div>
    </div>
  )
}

export default General