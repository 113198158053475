import React from 'react'
import "./Team.scss"

const Team = () => {
  return (
    <div className='teamContainer'>
        <div className="teamTitle">
            <h1>Our team</h1>
        </div>
        <div className="teamMember">
            <div className="memberItem">
                <img src="https://placehold.co/600x400/png" alt=""/>
                <div className="memberText">
                    <h4 className="name">Hassan Musa</h4>
                    <h4 className="position">National IT</h4>
                    <p className="duties">
                        To main all computer system within the organization, including both hardware and software system. Also to train other staff memebers on how to use computer and other related component
                     </p>
                </div>
            </div>
            <div className="memberItem">
                <img src="https://placehold.co/600x400/png" alt=""/>
                <div className="memberText">
                    <h4 className="name">Hassan Musa</h4>
                    <h4 className="position">National IT</h4>
                    <p className="duties">
                        To main all computer system within the organization, including both hardware and software system. Also to train other staff memebers on how to use computer and other related component
                     </p>
                </div>
            </div>
            <div className="memberItem">
                <img src="https://placehold.co/600x400/png" alt=""/>
                <div className="memberText">
                    <h4 className="name">Hassan Musa</h4>
                    <h4 className="position">National IT</h4>
                    <p className="duties">
                        To main all computer system within the organization, including both hardware and software system. Also to train other staff memebers on how to use computer and other related component
                     </p>
                </div>
            </div>
            <div className="memberItem">
                <img src="https://placehold.co/600x400/png" alt=""/>
                <div className="memberText">
                    <h4 className="name">Hassan Musa</h4>
                    <h4 className="position">National IT</h4>
                    <p className="duties">
                        To main all computer system within the organization, including both hardware and software system. Also to train other staff memebers on how to use computer and other related component
                     </p>
                </div>
            </div>
            <div className="memberItem">
                <img src="https://placehold.co/600x400/png" alt=""/>
                <div className="memberText">
                    <h4 className="name">Hassan Musa</h4>
                    <h4 className="position">National IT</h4>
                    <p className="duties">
                        To main all computer system within the organization, including both hardware and software system. Also to train other staff memebers on how to use computer and other related component
                     </p>
                </div>
            </div>
            <div className="memberItem">
                <img src="https://placehold.co/600x400/png" alt=""/>
                <div className="memberText">
                    <h4 className="name">Hassan Musa</h4>
                    <h4 className="position">National IT</h4>
                    <p className="duties">
                        To main all computer system within the organization, including both hardware and software system. Also to train other staff memebers on how to use computer and other related component
                     </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Team