export const activitiesData = [
    {
        picture: "1705663327738.jpg",
        caption: "Handing over of ebola kits",
        description: "Handing over of ebola kits, to the CHO in charge of Easters Clinic at mile 91.",
        fundedBy: "Christian Health Association of Sierra Leone (CHASL).",
        category: "Health"
    },
    {
        picture: "1705663513087.jpg",
        caption: "Graduation Ceremony.",
        description: "Graduation ceremony at christian community development vocational training center mile 91.",
        fundedBy: "KFW Germany through National HIV/AIDS Secretariat (NAS).",
        category: "Education"
    },
    {
        picture: "1705663588626.jpg",
        caption: "Swamp Development.",
        description: "Inland valley swamp(IVS) development at Wankaly Themneh Village, Yoni Mabanta Chiefdom, Tonkolili District.",
        fundedBy: "CORD Aid Netherlands.",
        category: "Agriculture"
    },
    {
        picture: "1705663667847.jpg",
        caption: "HIV/AIDS Sensization.",
        description: "HIV/AIDS sensization camping at mile 91, Tonkolili District.",
        fundedBy: "KFW Germany through National HIV/AIDS Secretariat (NAS).",
        category: "Health"
    },
    {
        picture: "1705663841424.jpg",
        caption: "Farmer Field School Training.",
        description: "Farmer field school training at Macorogbah, Yoni Mabanta Chiefdom Tonkolili District.",
        fundedBy: "CORD Aid Netherlands.",
        category: "Agriculture"
    },
    {
        picture: "1705663914237.jpg",
        caption: "Pluging of Harvested Groundnut.",
        description: "Pluging of harvested groundnut at Macorogbah, Yoni Mabanta Chiefdom Tonkolili District.",
        fundedBy: "CORD Aid Netherlands.",
        category: "Agriculture"
    },
    {
        picture: "1705664116478.jpg",
        caption: "Handing over of certificate.",
        description: "Handing over of certificate to the graduants at christian community development vocational training center mile 91.",
        fundedBy: "CCDP.",
        category: "Education",
        date: "March 2003"
    },
    {
        picture: "1705664280185.jpg",
        caption: "Handing over of Ebola kits and medical supplies.",
        description: "Handing over of ebola kits and other medical supplies at Yonibana community health center.",
        fundedBy: "Christian Health Association of Sierra Leone (CHASL).",
        category: "Health",
        date: "February 2015"
    },
    {
        picture: "1705664327895.jpg",
        caption: "Handing over of Ebola kits and medical supplies.",
        description: "Handing over of ebola kits and other medical supplies at Rochen Kamandao community health center.",
        fundedBy: "Christian Health Association of Sierra Leone (CHASL).",
        category: "Health",
        date: "March 2015"
    },
    {
        picture: "1705664459489.jpg",
        caption: "Interviewing of farmers.",
        description: "Interview conducted by the agricultural field officer at Magbasha bana village, Yoni Mabanta cheifdom Tonkolili District.",
        fundedBy: "CORD Aid Netherlands.",
        category: "Agriculture",
        date: "August 2015"
    },
    {
        picture: "1705664575431.jpg",
        caption: "Weading of groundnut farm.",
        description: "Weading of groundnut farm at Makontah village Yoni Mabanta cheifdom Tonkolili District.",
        fundedBy: "CORD Aid Netherlands.",
        category: "Agriculture",
        date: "May 2014-2015"
    },
    {
        picture: "1705664626452.jpg",
        caption: "Village savings and loans meeting (VSL).",
        description: "Village savings and loans meeting at Magbasha Bana village Yoni Mabanta cheifdom Tonkolili District.",
        fundedBy: "CORD Aid Netherlands.",
        category: "Enterpreneurship",
        date: "October 2014"
    },
    {
        picture: "image002.jpg",
        caption: "Land clearing, stumping and layingout.",
        description: "Land clearing, stumping and layingout for the construction of grain store building at Mamoi Konta village Masimera cheifdom Port Loko District.",
        fundedBy: "UN Staff One Percent for development fund Vienna Austria.",
        category: "Agriculture", 
        date: "February 2013-2014"
    },
    {
        picture: "image005.jpg",
        caption: "Women fetching water to cast blocks.",
        description: "Women fetching water to cast blocks for the construction of grain store building at Mamoi Konta village Masimera cheifdom Port Loko District.",
        fundedBy: "UN Staff One Percent for development fund Vienna Austria.",
        category: "Agriculture",
        date: "February 2014"
    },
    {
        picture: "image006.jpg",
        caption: "Construction of the wall.",
        description: "Construction of the wall for the grain store building at Mamoi Konta village Masimera cheifdom Port Loko District.",
        fundedBy: "UN Staff One Percent for development fund Vienna Austria.",
        category: "Agriculture",
        date: "February 2014"
    },
    {
        picture: "image010.jpg",
        caption: "Roofing of the building.",
        description: "Roofing of the grain store building at Mamoi Konta village Masimera cheifdom Port Loko District.",
        fundedBy: "UN Staff One Percent for development fund Vienna Austria.",
        category: "Agriculture",
        date: "February 2014"
    },
    {
        picture: "image013.jpg",
        caption: "Completed building.",
        description: "The completed grain store building at Mamoi Konta village Masimera cheifdom Port Loko District.",
        fundedBy: "UN Staff One Percent for development fund Vienna Austria.",
        category: "Agriculture",
        date: "February 2014"
    },
    {
        picture: "image015.jpg",
        caption: "Handing over of the grain store building to the community.",
        description: "Handing over of the grain store building to the community at Mamoi Konta village Masimera cheifdom Port Loko District.",
        fundedBy: "UN Staff One Percent for development fund Vienna Austria.",
        category: "Agriculture",
        date: "February 2014"
    },
    {
        picture: "IMG-20240101-WA0012.jpg",
        caption: "Completed dug well.",
        description: "The completed dug water well at Manungbu Village Buya Romendeh cheifdom Port Loko District.",
        fundedBy: "NaCSA.",
        category: "Health",
        date: "May 2005, 2006"
    },
    {
        picture: "IMG-20240103-WA0015.jpg",
        caption: "Complection and Installation Process.",
        description: "Complection and installation process at Manungbu Village Buya Romendeh cheifdom Port Loko District.",
        fundedBy: "NaCSA.",
        category: "Health",
        date: "May 2005, 2006"
    },
    {
        picture: "IMG-20240101-WA0028.jpg",
        caption: "Installation Process.",
        description: "Installation process at Manungbu Village Buya Romendeh cheifdom Port Loko District.",
        fundedBy: "NaCSA.",
        category: "Health",
        date: "May 2005, 2006"
    },
    {
        picture: "PEPPER.jpg",
        caption: "Pepper farm.",
        description: "Pepper farm at Makong Village and Boilnina village Kholife Mabang cheifdom Tonkolili District.",
        fundedBy: "Tear Fund Natherlands.",
        category: "Agriculture",
        date: "February 2011."
    },
    {
        picture: "PEPPER.jpg",
        caption: "Pepper farm.",
        description: "Pepper farm at Makong Village and Boilnina village Kholife Mabang cheifdom Tonkolili District.",
        fundedBy: "ACDI/VOCA(SNAP) USA.",
        category: "Agriculture",
        date: "February 2016."
    },
    {
        picture: "GROUNDNUTS.jpg",
        caption: "Groundnut Farm.",
        description: "Ground farm at Makong Village, Gaindema Village,Maworr Village and Boilnina village in Kholife Mabang and Yoni Mamalie cheifdoms Tonkolili District.",
        fundedBy: "ACDI/VOCA(SNAP) USA.",
        category: "Agriculture",
        date: "September 2017."
    },
    {
        picture: "photoone.jpg",
        caption: "Rice Farm/Food security.",
        description: "Rice farm at Mathinka Village, Marunia Village, Masakia Village, Mamanso Village and Bukuku Village in Kholife Mabang cheifdom Tonkolili District.",
        fundedBy: "Tear Fund Natherlands.",
        category: "Agriculture",
        date: "May 2005."
    },
    {
        picture: "Training-3.jpg",
        caption: "Skill Training/Tailoring.",
        description: "Skill training/tailoring at CCDP vocatinal training center",
        fundedBy: "Africa Women Fund Ghana.",
        category: "Education",
        date: "March 2009."
    },
    {
        picture: "Training-4.jpg",
        caption: "Skill Training/Embroidery.",
        description: "Skill training/embroidery at CCDP vocatinal training center",
        fundedBy: "KFW German Through National HIV/AIDS Secretariat Sierra Leone.",
        category: "Education", 
        date: "2021, 2022 and 2023."
    },
    {
        picture: "Supply-1.jpg",
        caption: "Support for children.",
        description: "Distributing food items to less previllage children",
        fundedBy: "Christian Community Development Programme(CCDP).",
        category: "Child Support", 
        date: "2020, 2021 and 2022."
    },
    {
        picture: "IMG_20231201_093420_663.jpg",
        caption: "Christian Community Development Programme Vocational Training Center.",
        description: "Christian community development programme vocational training center building at mile 91.",
        fundedBy: "Christian Community Development Programme(CCDP).",
        category: "Education", 
        date: "2002 and 2003."
    },
    {
        picture: "IMG_20231204_110713_914.jpg",
        caption: "Christian Community Development Programme Vocational Training Center Students.",
        description: "Students standing in front of Christian community development programme vocational training center building at mile 91.",
        fundedBy: "Christian Community Development Programme(CCDP).",
        category: "Education", 
        date: "2002 and 2003."
    }

]