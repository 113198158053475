import React from 'react'
import "./Regions.scss"
import { HiArrowRightCircle } from "react-icons/hi2";

const Regions = () => {
    return (
        <div className="regions">
            <div className='RegionBox'>
                <div className='RegionHeader'>
                    North West Region
                </div>
                <div className="districts">
                    <div className='districtItems'>
                    <HiArrowRightCircle className='ItemIcon' />
                    <span>Port Loko District</span>
                    </div>
                    <div className='districtItems'>
                    <HiArrowRightCircle className='ItemIcon' />
                    <span>Karena District</span>
                    </div>
                </div>
            </div>
            <div className='RegionBox'>
                <div className='RegionHeader'>
                    Northern Region
                </div>
                <div className="districts">
                    <div className='districtItems'>
                    <HiArrowRightCircle className='ItemIcon' />
                    <span>Tonkolili District</span>
                    </div>

                    <div className='districtItems'>
                    <HiArrowRightCircle className='ItemIcon' />
                    <span>Koinadugu District</span>
                    </div>
                </div>
            </div>

            <div className='RegionBox'>
                <div className='RegionHeader'>
                    Southern Region
                </div>
                <div className="districts">
                    <div className='districtItems'>
                    <HiArrowRightCircle className='ItemIcon' />
                    <span>Bo District</span>
                    </div>
                    
                </div>
            </div>

            <div className='RegionBox'>
                <div className='RegionHeader'>
                    Eastern Region
                </div>
                <div className="districts">
                    <div className='districtItems'>
                    <HiArrowRightCircle className='ItemIcon' />
                    <span>Kailahun District</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Regions