import React from 'react'
import "./Activities.scss"
import { activitiesData } from '../../data/data'
import ActivitiesItem from '../../component/activitiesItem/ActivitiesItem'


const Activities = () => {
    return (
        <div className='activitiesContainer'>
            <div className="activitiesHeading">
                <h1>Events/Activities</h1>
            </div>
            <div className="activities">
                {
                    activitiesData.map((activities, index) => (
                       <ActivitiesItem key={index} activities={activities} />
                    ))
                }
            </div>
        </div>
    )
}

export default Activities