import React from 'react'
import { useParams } from 'react-router-dom'
import { activitiesData  } from '../../data/data'
import './Details.scss'

const Deatails = () => {
    const{ pictureId } = useParams()
    const item = activitiesData.find((activitie) => activitie?.picture === pictureId)
  return (
    <div className='details'>
      <div className="itemBox">
      <img src={`/images/${item?.picture}`} alt="" />
    <div className="itemText">
        <h4>{item?.caption}</h4>
        <p>{item?.description}</p>
        <p className='founded_by'>Funded By {item?.fundedBy}</p>
        <span className='category'>Category: {item?.category}</span>
        {item?.date && <p className='year'>Year {item?.date}</p>}
    </div>
      </div>
    </div>
  )
}

export default Deatails