import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const ActivitiesItem = ({ activities }) => {
    const{ picture, caption, description } = activities
  return (
    <div className="activitiesItem">
    <img src={`/images/${picture}`} alt="" />
    <div className="activitiesText">
        <h4>{caption}</h4>
        <p>{description?.slice(0,100)}..</p>
    </div>
    <div className="activitiesButton">
        <Link to={`${picture}`}>Read More <AiOutlineArrowRight className='arrowRight' /></Link>
    </div>
</div>
  )
}

export default ActivitiesItem