import React from "react";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <div className="aboutContainer">
      <h1>About us</h1>
      <p>
        The Christian Community Development Programme (CCDP) was founded in 1989
        by Gallow United Methodist Church and the Paniel Assemblies of God
        church in Mile 91 in the Tonkolili District Northern Provience of Sierra Leone. CCDP was
        registered as Non-Governmental Organization (NGO) is also registered with the Sierra Leone
        Association of Non-Governmental Organizations (SLANGO).
      </p>
      <div className="values">
        <div className="value_header">Our Values</div>
        <div className="value_description">
          <p>
            CCDP as an organization stands for certain values, which have formed
            the very foundation of our existence and all of our actions. These
            are:
          </p>
          <u className="value_list">
            <li>
              We are a Christian organization. All of our work is development
              and explains what we do
            </li>
            <li>We promote participation of all stakeholders in all we do</li>
            <li>
              As a result of our work, the client we serve must become
              self-reliant in championing their own development
            </li>
            <li>
              Our work targets the most vulnerable in the communities and
              societies in which we work
            </li>
            <li>
              Hard work, transparency, and accountability guide our operations
              we promote both vertical and horizontal accountability.
            </li>
          </u>
        </div>
      </div>
      <div className="vision">
        <div className="vision_header">Our Vision</div>
        <div className="vision_description">
          <p>Self rural communities in Sierra Leone, especially children, youth and
          Vulnerable women growing in their commitment to improved the standards of
          living</p>.
        </div>
      </div>
      <div className="mission">
        <div className="mission_header">Our Mission</div>
        <div className="mission_description">
          <p>
            CCDP strives to achieve sustainable improvement in the lives of
            rural disadvantages persons, through a process by:
          </p>
          <u className="mission_list">
            <li>
              Seek poverty alleviation of the rural poor communities in Sierra
              Leone
            </li>
            <li>
              Build the capacity of local communities, groups and structures on
              development
            </li>
            <li>
              Form the right alliances for funding to support the work in
              communities in area of operation
            </li>
          </u>
        </div>
      </div>
      <div className="programmes">
        <div className="programmes_header">Our Programmes</div>
        <div className="programmes_description">
          <p>
            In the development of this strategic plan, and building upon
            analyses of the problem in the country, CCDP recognized that it
            programming will require an integrated approach. Although many
            strategic approaches will be cross-cutting and integrated into other
            programs, CCDP will focus its programmes
            on the following;
          </p>
          <ul className="programmes_list">
            <li>Health, Water, and Sanitation</li>
            <li>Agriculture and livelihoods</li>
            <li>Education and Skills Training</li>
            <li>Child Protection</li>
            <li>Enterpreneurship</li>
          </ul>
          <p>
            Other strategic priorities for CCDP in Sierra Leone are the
            promotion of innovative and efficient management structures, staff
            capacity building, building alliances, and striving for diversified
            and ample funding. While not a “Programming” sector CCDP recognizes
            that management quality is crucial to achieving its priority
            programming objectives. It should be note that each of these areas
            is different in nature and our strategies reflect this in our varied
            approaches.
          </p>
        </div>
      </div>
      <div className="our_management">
        <div className="management_header">CCDP Management</div>
        <div className="management_description">
          <p>
            CCDP is governed by a Board of Directors with responsibility for
            providing policy direction in the management and administration of
            the organization. An Executive Director who reports to the Board
            takes charge in the management and coordination of the
            administrative and technical Program functions performed by staff,
            Professional Consultants and Partners. Another key member of
            management is an Accountant/Auditor. Additionally, Programme and
            administrative managers work up to the Executive Director and
            support the field staff in execution of project activities. Other
            members of staff of the organization are District officers, Field
            and community staff, an Administrative Secretary and Office
            Assistant.
          </p>
        </div>
        <div className="organization_structure">
          <div className="organization_structure_header">
            Organizational Structure of CCDP
          </div>
          <ul className="structure_list">
            <li>Board of Directors</li>
            <li>Executive Director</li>
            <li>Administrative/Finance</li>
            <li>Program Manager</li>
            <li>Admin Secretary</li>
            <li>District Coordinator</li>
            <li>Office Assistance</li>
            <li>Field Staff</li>
            <li>Community Volunteers</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
